import { ComboboxRoot } from "@datamole/wds-component-combobox";

import { MultiSelectContent } from "components/ui/MultiSelect/MultiSelectContent";
import { MultiSelectDivier } from "components/ui/MultiSelect/MultiSelectDivider";
import { MultiSelectTrigger } from "components/ui/MultiSelect/MultiSelectTrigger";

export const MultiSelect = Object.assign(ComboboxRoot, {
    Trigger: MultiSelectTrigger,
    Content: Object.assign(MultiSelectContent, {
        Divider: MultiSelectDivier,
    }),
});

export { useMultiSelect } from "components/ui/MultiSelect/useMultiSelect.hook";
