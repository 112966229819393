import type { ReactNode } from "react";

import { hasAccessBasedOnArea } from "components/UserAreaBoundary/user-area-boundary.utils";
import { useAppSelector } from "store";
import type { UserAreaName } from "types/user.types";

type Props = {
    areas: UserAreaName[];
    children: ReactNode;
    fallback?: ReactNode;
    allowDeveloperMode?: boolean;
};

const UserAreaBoundary = (props: Props) => {
    const { areas, children, allowDeveloperMode = false, fallback = null } = props;

    const userAreas = useAppSelector((state) => state.user.areas);
    const developerMode = useAppSelector((state) => state.user.developerMode);

    const canShow = hasAccessBasedOnArea({
        userAreas,
        allowUserAreas: areas,
        developerMode,
        allowDeveloperMode,
    });

    if (!canShow) {
        return fallback;
    }

    return children;
};

export { UserAreaBoundary };
