export const roundNumber = (number: number, precision: number): number => {
    const factor = 10 ** precision;
    return Math.round(number * factor) / factor;
};

export type Range = { min: number; max: number };

export const getMinMax = (values: (number | null)[]): Range => {
    const numbers = values.filter((value) => value !== null);

    if (!values.length) {
        return {
            min: 0,
            max: 0,
        };
    }

    return { min: Math.min(...numbers), max: Math.max(...numbers) };
};

export const fixedNumberPrecision = (value: number | string) => (typeof value === "number" ? value.toFixed(2) : value);
