import { useMemo } from "react";

import { WidgetFrame } from "components/layout/WidgetFrame";
import { useBooleanFilters, BooleanFilter } from "components/ui/BooleanFilters";
import { useMultiSelect } from "components/ui/MultiSelect";
import {
    Column,
    createChipsColumn,
    createDataColumn,
    createDataSortableColumn,
    useSort,
} from "components/ui/table-final-saviour/Table";
import { TableCellLink } from "components/ui/table-final-saviour/Table/TableCell/TableCellLink";
import { useSearch } from "components/ui/table-final-saviour/Table/hooks/useSearch";
import { Chip, ChipVariant } from "components/ui-deprecated/Chip";
import { useDataSource } from "hooks/useDataSource";
import { AgreementsMultiSelect } from "pages/DashboardPage/tss/widgets/InsightsWidget/AgreementsMultiSelect";
import {
    agreementsSelectOptions,
    filterBySelectedAgreements,
} from "pages/DashboardPage/tss/widgets/InsightsWidget/AgreementsMultiSelect.utils";
import { InsightsWidgetExpandedContent } from "pages/DashboardPage/tss/widgets/InsightsWidget/InsightsWidgetExpandedContent";
import type {
    InsightsWidgetDataRow,
    InsightsWidgetDataSourceRow,
} from "pages/DashboardPage/tss/widgets/InsightsWidget/insights-widget.types";
import { routes } from "routes";
import { generatePath } from "routes/routes.utils";

type Props = {
    queryParameters: object;
};

const booleanFilters = [
    {
        key: "pmOverdue",
        title: "PM (Over)due",
        condition: (row) => Boolean(row.PMSoon),
    },
] as const satisfies BooleanFilter<InsightsWidgetDataRow>[];

const InsightsWidget = (props: Props) => {
    const { queryParameters } = props;

    const dataSourceParameters = {
        ...queryParameters,
        userRoleLink: "tl",
    };

    const dataSource = useDataSource<InsightsWidgetDataSourceRow[]>("Insights_Table", dataSourceParameters);
    const { data = [] } = dataSource;

    const {
        multiSelect: agreementsMultiSelect,
        getClearOptionProps: getClearAgrementsOptionProps,
        selected: selectedAgreements,
    } = useMultiSelect(agreementsSelectOptions);

    const { sortControls, applySort } = useSort<InsightsWidgetDataRow>({
        initialSortConfig: { key: "InsightCount", order: "desc", dataType: "number" },
    });

    const columns = useMemo(
        () =>
            [
                createDataSortableColumn({
                    dataKey: "CustomerName",
                    title: "Customer Name",
                    dataType: "string",
                    width: 200,
                    ...sortControls,
                    components: {
                        TableCell: (cellProps) => {
                            const { row } = cellProps;
                            const link = generatePath(routes.customerDetail, {
                                params: {
                                    customerNumber: row.CustomerNumber,
                                },
                            });

                            return <TableCellLink<InsightsWidgetDataRow> to={link!} {...cellProps} />;
                        },
                    },
                }),
                createDataSortableColumn({
                    dataKey: "InsightCount",
                    title: "Devices",
                    dataType: "number",
                    align: "right",
                    width: 100,
                    ...sortControls,
                }),
                createDataSortableColumn({
                    dataKey: "DaysUntilNextPM",
                    title: "Days Until Next PM",
                    dataType: "number",
                    align: "right",
                    width: 120,
                    ...sortControls,
                }),
                createChipsColumn({
                    title: "Insight Categories",
                    getChips: (props) => {
                        const { row } = props;
                        const { InsightCategoryList, InsightLevelList } = row;
                        if (!InsightCategoryList) return null;

                        return InsightCategoryList.map((category: unknown, index: number) => {
                            const level = InsightLevelList?.[index];
                            let variant: ChipVariant = "default";
                            if (level === "red") {
                                variant = "danger";
                            }

                            return <Chip key={index} label={String(category)} variant={variant} />;
                        });
                    },
                }),
            ] as Column<InsightsWidgetDataRow>[],
        [sortControls]
    );

    const searchColumns: Column<InsightsWidgetDataRow>[] = useMemo(
        () => [
            ...columns,
            createDataColumn({
                dataKey: "InsightCategory",
                title: "Insight Categories",
                dataType: "string",
            }),
        ],
        [columns]
    );

    const booleanFiltersOptions = useBooleanFilters({
        filters: booleanFilters,
        defaultActiveFilters: ["pmOverdue"],
    });
    const { applyFilters } = booleanFiltersOptions;
    const { searchValue, setSearchValue, applySearch } = useSearch({
        columns: searchColumns as Column<InsightsWidgetDataRow>[],
    });

    const searchedData = useMemo(() => {
        const parsedData = data.map((row) => {
            const { InsightCategory, InsightLevel } = row;
            const categories = InsightCategory.split(",") as ("red" | "orange")[];
            const levels = InsightLevel.split(",");

            return {
                ...row,
                InsightCategoryList: categories,
                InsightLevelList: levels,
            } satisfies InsightsWidgetDataRow;
        });

        const filteredData = filterBySelectedAgreements(applyFilters(parsedData), selectedAgreements);

        return applySort(applySearch(filteredData));
    }, [applySearch, applySort, applyFilters, data, selectedAgreements]);

    return (
        <>
            <WidgetFrame.Title title={"Insight List"} icon={"lely-icon-show"}>
                <WidgetFrame.Title.Search value={searchValue} setValue={setSearchValue} />

                <WidgetFrame.Title.Actions>
                    <AgreementsMultiSelect
                        multiSelect={agreementsMultiSelect}
                        getClearOptionProps={getClearAgrementsOptionProps}
                        selected={selectedAgreements}
                    />
                </WidgetFrame.Title.Actions>

                <WidgetFrame.Title.BooleanFilters {...booleanFiltersOptions} />

                <WidgetFrame.Title.Information pageId={189104158} />
            </WidgetFrame.Title>
            <WidgetFrame.TableExpandable
                dataSource={{ ...dataSource, data: searchedData }}
                columns={columns}
                maxHeight={800}
                ExpandedContent={InsightsWidgetExpandedContent}
                getRowId={(row) => row.CustomerNumber}
            />
        </>
    );
};

export { InsightsWidget };
