// @ts-strict-ignore
import { memo } from "react";
import styled from "styled-components";

import { TableRow } from "components/ui/table-final-saviour/Table/TableRow";
import { TableRowProps } from "components/ui/table-final-saviour/Table/TableRow/table-row.types";
import { TableContextExpandable } from "components/ui/table-final-saviour/Table/table-expandable.types";

const TableRowExpanded = styled.tr`
    border-bottom: 1px solid #efedea;
`;

const TableRowNoHeight = styled.tr`
    height: 0;
`;

const TableCellNoPadding = styled.td`
    padding: 0;
`;

interface Props extends TableRowProps {
    item: {
        _type: "row" | "expandable_content";
    };
    context: TableContextExpandable<any>;
}

const TableRowExpandable = (props: Props) => {
    const { context, ...otherProps } = props;

    const { getIsExpandedRow, columnCount, ExpandedContent } = context;
    const item = otherProps.item;

    const isRowType = item._type === "row";
    if (isRowType) {
        return <TableRow {...otherProps} data-type={item._type} />;
    }

    const rowProps = {
        "data-type": item._type,
        style: props.style,
        "data-index": props["data-index"],
        "data-item-index": props["data-item-index"],
        "data-known-size": props["data-known-size"],
        "data-row-type": props["data-row-type"],
    };

    const isExpanded = getIsExpandedRow(item);
    if (!isExpanded) {
        return <TableRowNoHeight {...rowProps} />;
    }

    return (
        <TableRowExpanded {...rowProps}>
            <TableCellNoPadding colSpan={columnCount}>
                <ExpandedContent row={item} />
            </TableCellNoPadding>
        </TableRowExpanded>
    );
};

const TableRowExpandableMemo = memo(TableRowExpandable);
export { TableRowExpandableMemo as TableRowExpandable };
