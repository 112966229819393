import { Tooltip } from "@datamole/wds-component-tooltip";
import { memo, type ReactNode } from "react";
import { styled } from "styled-components";

import {
    type TableHeaderCellProps,
    TableHeaderCellStyled,
} from "components/ui/table-final-saviour/Table/TableHeaderCell/TableHeaderCell";
import { Row } from "components/ui/table-final-saviour/Table/table.types";

const TooltipTriggerStyled = styled(Tooltip.Trigger)`
    text-decoration: underline dotted;
`;

const TooltipContentStyled = styled(Tooltip.Content)`
    z-index: ${({ theme }) => theme.zIndex.tableFinalSaviourHeader + 1};
`;

interface Props<T extends Row> extends TableHeaderCellProps<T> {
    tooltip: ReactNode;
}

const TableHeaderCellWithTooltip = <T extends Row>(props: Props<T>) => {
    return (
        <TableHeaderCellStyled className={props.className} $align={props.align} $width={props.width}>
            <Tooltip>
                <TooltipTriggerStyled>{props.children}</TooltipTriggerStyled>
                <TooltipContentStyled>{props.tooltip}</TooltipContentStyled>
            </Tooltip>
        </TableHeaderCellStyled>
    );
};

const TableHeaderCellWithTooltipMemo = memo(TableHeaderCellWithTooltip);

export { TableHeaderCellWithTooltipMemo as TableHeaderCellWithTooltip };
