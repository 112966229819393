import { chartColor, chartColorCategorical } from "components/ui/Chart";

export const KPIColor = {
    red: chartColor.red,
    green: chartColor.darkGreen,
    blue: chartColor.darkTeal,
    orange: chartColor.orange,
    yellow: "#ffc700",

    pink: "#ff9f9c",
    yellow60: chartColorCategorical.yellow60,
    cyan60: chartColorCategorical.cyan60,
};
export type KPIColor = keyof typeof KPIColor;

export const THRESHOLD_DATASET_ID = "threshold-dataset";
