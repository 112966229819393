import { ComboboxTrigger } from "@datamole/wds-component-combobox";
import type { HTMLAttributes } from "react";

type Props = HTMLAttributes<HTMLDivElement>;

export const MultiSelectTrigger = (props: Props) => {
    const { children, ...rest } = props;

    return (
        <ComboboxTrigger {...rest}>
            <ComboboxTrigger.Value>{children}</ComboboxTrigger.Value>

            <ComboboxTrigger.Actions>
                <ComboboxTrigger.Toggle />
            </ComboboxTrigger.Actions>
        </ComboboxTrigger>
    );
};
