import { memo } from "react";
import styled, { css } from "styled-components";

import {
    tableCellBaseCSS,
    TableCellBaseCSSOptions,
} from "components/ui/table-final-saviour/Table/TableCell/table-cell.styles";
import { Row, TableCellBaseProps } from "components/ui/table-final-saviour/Table/table.types";

type TableCellFontStyle = "default" | "mono";
interface TableCellCSSOptions extends TableCellBaseCSSOptions {
    $fontStyle?: TableCellFontStyle;
    $rowIndex: number;
}

export const oddCellCSS = css`
    background-color: ${({ theme }) => theme.color.dairyLight};
`;

const TableCellStyled = styled.td<TableCellCSSOptions>`
    ${tableCellBaseCSS};

    background-color: ${({ theme }) => theme.color.white};

    font-family: ${({ $fontStyle }) => ($fontStyle === "mono" ? "monospace" : undefined)};

    ${({ $rowIndex }) => $rowIndex % 2 === 1 && oddCellCSS};
`;

interface Props<T extends Row> extends TableCellBaseProps<T> {
    align?: TableCellCSSOptions["$align"];
    verticalAlign?: TableCellCSSOptions["$verticalAlign"];
    fontStyle?: TableCellCSSOptions["$fontStyle"];
    width?: TableCellCSSOptions["$width"];
}

const TableCell = <T extends Row>(props: Props<T>) => {
    return (
        <TableCellStyled
            className={props.className}
            $align={props.align}
            $fontStyle={props.fontStyle}
            $rowIndex={props.rowIndex}
            $verticalAlign={props.verticalAlign}
            $width={props.width}
        >
            {props.children}
        </TableCellStyled>
    );
};

const TableCellMemo = memo(TableCell) as typeof TableCell;

export { TableCellMemo as TableCell, TableCellStyled };
export type { Props as TableCellProps, TableCellCSSOptions };
