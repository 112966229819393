// @ts-strict-ignore
import { ComponentProps, ComponentType, forwardRef, RefCallback, useCallback, useMemo } from "react";
import { FixedHeaderContent, ScrollerProps, TableComponents as VirtuosoTableComponents } from "react-virtuoso";

import { TableBase as TableDefault } from "components/ui/table-final-saviour/Table/TableBase";
import { TableProps } from "components/ui/table-final-saviour/Table/TableBase/table.types";
import { TableHead as TableHeadDefault, TableHeadProps } from "components/ui/table-final-saviour/Table/TableHead";
import {
    TableHeaderRow as TableHeaderRowDefault,
    TableHeaderRowProps,
} from "components/ui/table-final-saviour/Table/TableHeaderRow";
import { TableRow as TableRowDefault, TableRowProps } from "components/ui/table-final-saviour/Table/TableRow";
import { Column, Row, TableComponents, TableContext } from "components/ui/table-final-saviour/Table/table.types";
import { theme } from "styles/theme";

type TableComponentProps<T> = ComponentProps<VirtuosoTableComponents<T>["Table"]>;
type TableHeadComponentProps<T> = ComponentProps<VirtuosoTableComponents<T>["TableHead"]>;
type TableRowComponentProps<T> = ComponentProps<VirtuosoTableComponents<T>["TableRow"]>;

type Options<T extends Row> = {
    components?: TableComponents;
    ref: RefCallback<HTMLTableElement>;
    columns: Column<T>[];
    minWidth?: number;
};

export const useTableComponents = <T extends Row, TContext extends TableContext>(options: Options<T>) => {
    const { ref, columns, minWidth } = options;

    const Table_: ComponentType<TableProps> = options.components?.Table ?? TableDefault;
    const TableHead: ComponentType<TableHeadProps> = options.components?.TableHead ?? TableHeadDefault;
    const TableHeaderRow: ComponentType<TableHeaderRowProps> =
        options.components?.TableHeaderRow ?? TableHeaderRowDefault;
    const TableRow: ComponentType<TableRowProps> = options.components?.TableRow ?? TableRowDefault;

    const VirtualizedScroller = useMemo(() => {
        const component = forwardRef<HTMLDivElement, ScrollerProps & { context: TContext }>((props, ref) => {
            const overflowY = props?.context?.hideScrollbar ? "hidden" : "auto";

            return <div {...props} ref={ref} style={{ ...props.style, overflowY }} />;
        });
        component.displayName = "VirtualizedScroller";
        return component;
    }, []);

    const TableWithAutoHeightRef = useCallback(
        (props: TableComponentProps<T>) => <Table_ {...props} ref={ref} style={{ minWidth }} />,
        [ref, Table_, minWidth]
    );

    const TableHeadWithColumns: ComponentType<TableHeadProps> = useMemo(() => {
        const component: ComponentType<TableHeadProps> = forwardRef<
            HTMLTableSectionElement,
            TableHeadComponentProps<T>
        >((props, ref) => {
            // Overwrite default library zIndex, so that we can have 2 levels of expanded tables
            const style = { ...props.style, zIndex: theme.zIndex.tableFinalSaviourHeader };
            return <TableHead {...props} style={style} ref={ref} columns={columns} />;
        });
        component.displayName = "TableHead";
        return component;
    }, [columns, TableHead]);

    const TableHeaderRowWithColumns = useCallback(
        () => <TableHeaderRow columns={columns} />,
        [columns, TableHeaderRow]
    );

    const TableRowWithRowIndex = useCallback(
        (props: TableRowComponentProps<T>) => <TableRow {...props} rowIndex={props["data-index"]} />,
        [TableRow]
    );

    const components = useMemo(
        () =>
            ({
                Scroller: VirtualizedScroller,
                Table: TableWithAutoHeightRef,
                TableHead: TableHeadWithColumns,
                TableHeaderRow: TableHeaderRowWithColumns,
                TableRow: TableRowWithRowIndex,
            }) satisfies VirtuosoTableComponents<T> & { TableHeaderRow: FixedHeaderContent },
        [
            VirtualizedScroller,
            TableWithAutoHeightRef,
            TableHeadWithColumns,
            TableHeaderRowWithColumns,
            TableRowWithRowIndex,
        ]
    );

    return components;
};
