import styled from "styled-components";

import { MultiSelect } from "components/ui/MultiSelect";
import { UseMultiSelectReturnType } from "components/ui/MultiSelect/useMultiSelect.hook";
import {
    agreementSelectId,
    agreementsSelectOptions,
    getAgreementSelectTriggerLabel,
} from "pages/DashboardPage/tss/widgets/InsightsWidget/AgreementsMultiSelect.utils";

const AgreementMultiSelectTrigger = styled(MultiSelect.Trigger)`
    height: 2.25rem;
    margin-top: -0.125rem;
    max-width: 300px;
`;

type Props = {
    multiSelect: UseMultiSelectReturnType["multiSelect"];
    getClearOptionProps: UseMultiSelectReturnType["getClearOptionProps"];
    selected: UseMultiSelectReturnType["selected"];
};

export const AgreementsMultiSelect = (props: Props) => {
    const { multiSelect, getClearOptionProps, selected } = props;

    const MultiSelectClearOption = () => {
        const clearOptionIndex = agreementsSelectOptions.findIndex((option) => option.id === agreementSelectId.Clear);

        return (
            <MultiSelect.Content.List.Option {...getClearOptionProps(clearOptionIndex)}>
                {agreementsSelectOptions[clearOptionIndex].name}
            </MultiSelect.Content.List.Option>
        );
    };

    return (
        <MultiSelect {...multiSelect.getRootProps()} placeholder="Filter by Agreement">
            <AgreementMultiSelectTrigger>{getAgreementSelectTriggerLabel(selected)}</AgreementMultiSelectTrigger>

            <MultiSelect.Content {...multiSelect.getContentProps()}>
                <MultiSelect.Content.List {...multiSelect.getListProps()}>
                    {multiSelect.options.map((option, index) => {
                        if (option.id === agreementSelectId.Clear) {
                            return null;
                        }

                        return (
                            <MultiSelect.Content.List.Option key={option.id} {...multiSelect.getOptionProps(index)}>
                                {option.name}
                            </MultiSelect.Content.List.Option>
                        );
                    })}

                    <MultiSelect.Content.Divider />
                    <MultiSelectClearOption />
                </MultiSelect.Content.List>
            </MultiSelect.Content>
        </MultiSelect>
    );
};
