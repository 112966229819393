// @ts-strict-ignore
import { Item } from "components/ui/Combobox/combobox.types";
import { DataWrapper, map, Row } from "deprecated/data-wrapper";

export const search = (items: Item[], inputValue: string) => {
    if (inputValue === "") return items;

    const inputValueNormalized = inputValue.toLocaleLowerCase();
    return items.filter((item) => {
        const foundMatch = ["value", "label"].some((key) => {
            return String(item[key]).toLocaleLowerCase().includes(inputValueNormalized);
        });
        return foundMatch;
    });
};

export const getRowLabel = (row: Row, displayField: string | string[]) => {
    return Array.isArray(displayField)
        ? displayField.map((key) => String(row[key])).join(" - ")
        : String(row[displayField]);
};

export const toItemsFromArray = <T>(
    data: T[] | undefined,
    options: {
        getValue: (row: T) => string;
        getLabel: (row: T) => string;
        sort?: (a: T, b: T) => number;
    }
): Item[] => {
    if (!data) return [];

    return data
        .toSorted((a, b) => {
            if (options.sort) {
                return options.sort(a, b);
            }

            const labelA = options.getLabel(a);
            const labelB = options.getLabel(b);

            return labelA.localeCompare(labelB);
        })
        .map((row) => {
            return {
                value: options.getValue(row),
                label: options.getLabel(row),
            };
        });
};

export const toItemsFromDataWrapper = (
    data: DataWrapper | null,
    valueField: string,
    displayField: string | string[]
): Item[] => {
    if (!data) return null;

    return map(data, (row) => ({
        value: String(row[valueField]),
        label: getRowLabel(row, displayField),
    })).toSorted((a, b) => {
        return a.label.localeCompare(b.label);
    });
};
