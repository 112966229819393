import { ChartDataset } from "chart.js";
import styled from "styled-components";

import { Chart, ChartData } from "components/ui/Chart";
import { dayjs } from "components/ui/DatePicker/date.utils";
import { KPINoData } from "components/ui/KPI/KPINoData";
import { createKPIChartConfiguration } from "components/ui/KPI/charts/KPIChart/kpi-chart.configuration";
import { createFillerDataset, createKPIDataset } from "components/ui/KPI/charts/KPIChart/kpi-chart.dataset";
import { KPIColor } from "components/ui/KPI/kpi.consts";
import { resolveKPIColor, getKPITimeScale } from "components/ui/KPI/kpi.utils";

const DoubleChartStyled = styled.div`
    grid-column: span 2;
    overflow: hidden;
`;

export type KPIDoubleChartDataPoint = {
    date: string;
    datasets: [{ value: number; label: string }, { value: number; label: string }];
};

type Props = {
    data: KPIDoubleChartDataPoint[];
    datasetOptions: {
        label: string;
        color: KPIColor;
    }[];
};

export const KPIDoubleChart = (props: Props) => {
    const { data: rawData, datasetOptions } = props;
    if (!rawData) return <KPINoData />;
    if (rawData.length < 2) return <KPINoData fallbackText={"Not enough data"} />;

    const data = rawData.sort((a, b) => dayjs(a.date).diff(dayjs(b.date)));

    const firstValue = data.at(0)!;
    const beforeLastValue = data.at(-2)!;
    const lastValue = data.at(-1)!;
    const timeScale = getKPITimeScale(firstValue.date, firstValue.date);

    const comparisonValues: number[] = data.flatMap(({ datasets }) => [datasets[0].value, datasets[1].value]);
    const suggestedMax = Math.max(...comparisonValues);
    const suggestedMin = Math.min(...comparisonValues);

    const daysBetweenDates = dayjs(lastValue.date).diff(dayjs(beforeLastValue.date), "days");
    const nextDate = dayjs(lastValue.date).add(daysBetweenDates, "days").format();

    const configuration = createKPIChartConfiguration({
        suggestedMax,
        suggestedMin,
        timeScale,
        getLabel: ({ formattedValue, dataset }: { formattedValue: string; dataset: ChartDataset }) => {
            return `${dataset.label}: ${formattedValue}`;
        },
    });

    const chartData: ChartData = {
        labels: [...data.map(({ date }) => date), nextDate],
        datasets: [
            createKPIDataset({
                label: datasetOptions[0].label,
                color: resolveKPIColor(datasetOptions[0].color),
                data: [...data.map(({ datasets }) => datasets.at(0)!.value)],
                keepPointsSameSize: true,
            }),
            createKPIDataset({
                label: datasetOptions[1].label,
                color: resolveKPIColor(datasetOptions[1].color),
                data: [...data.map(({ datasets }) => datasets.at(1)!.value)],
                keepPointsSameSize: true,
            }),
            createFillerDataset({
                data: data,
                value: Math.max(lastValue.datasets[0].value, lastValue.datasets[1].value),
                color: "#ffffff",
            }),
        ],
    };

    return (
        <DoubleChartStyled>
            <Chart configuration={configuration} data={chartData} />
        </DoubleChartStyled>
    );
};
