import { z } from "zod";

import { dayjs } from "components/ui/DatePicker/date.utils";
import { api as baseApi } from "store/api";

export const TAG_NOTIFICATION = "NOTIFICATION";

export type NotificationMessage = {
    id: string;
    text: string;
    iconId: string;
    validity: Date;
};

const notificationSchema = z
    .object({
        id: z.string(),
        text: z.string(),
        iconId: z.string(),
        validity: z.coerce.date(),
    })
    .passthrough();

const api = baseApi
    .enhanceEndpoints({
        addTagTypes: [TAG_NOTIFICATION],
    })
    .injectEndpoints({
        endpoints: (builder) => ({
            getNotifications: builder.query<NotificationMessage[], { isRead: boolean; limit?: number; skip?: number }>({
                query: (params) => {
                    const urlSearchParams = new URLSearchParams({ read: params.isRead.toString() });
                    if (params.limit) {
                        urlSearchParams.append("limit", params.limit?.toString());
                    }
                    if (params.skip) {
                        urlSearchParams.append("skip", params.skip?.toString());
                    }

                    return {
                        url: `notification?${urlSearchParams}`,
                        method: "GET",
                    };
                },
                transformResponse: (response) => {
                    const result = z.array(notificationSchema).safeParse(response);
                    if (result.success) {
                        return result.data.sort((a, b) => dayjs(b.validity).diff(dayjs(a.validity))) as any;
                    } else {
                        // eslint-disable-next-line no-console
                        console.error("Received notification data in a wrong format");
                        throw (result as any).error;
                    }
                },
                providesTags: [TAG_NOTIFICATION],
            }),
            setNotificationRead: builder.mutation<void, string>({
                query: (id) => ({
                    url: `notification/${id}/read`,
                    method: "POST",
                }),
                invalidatesTags: [TAG_NOTIFICATION],
            }),
        }),
    });

export const { useGetNotificationsQuery, useLazyGetNotificationsQuery, useSetNotificationReadMutation } = api;
