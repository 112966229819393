import { useState, useRef, useEffect, ReactNode } from "react";

import Portal from "components/ui-deprecated/Portal/Portal";
import type { FormatConfig } from "deprecated/data-utils";
import { format } from "deprecated/data-utils";
import { useMousePosition } from "deprecated/hooks";
import "components/ui-deprecated/Tooltip/Tooltip.less";

export type TooltipProps = {
    // Whether tooltip should be shown or not
    show: boolean;
    // Tooltip title
    title: string | null;
    // Row of data to display in tooltip
    content: Record<string, unknown>;
    // Name of field in content for highlighting on hover
    activeKey: string | null;
    // Whether to display keys or not
    displayKey: boolean;
    // Colors of content
    colors: Record<string, string> | null;
    // Child elements of tooltip. They will be appended to the tooltip table
    children: ReactNode | null;
    // Position of tooltip
    position: {
        x: number;
        y: number;
        padding: number;
    } | null;
} & FormatConfig;

Tooltip.defaultProps = {
    show: false,
    title: null,
    content: {},
    activeKey: null,
    displayKey: true,
    colors: null,
    children: null,
    position: null,
};

/**
 * Deprecated - use `@datamole/wds-component-tooltip` instead.
 */
export default function Tooltip(props: TooltipProps) {
    const { show, title, content, activeKey, displayKey, colors, children, position } = props;
    const tooltipRef = useRef<null | HTMLDivElement>(null);
    const [tooltipWidth, setTooltipWidth] = useState<number>(150);
    const [tooltipHeight, setTooltipHeight] = useState<number>(150);
    // Position tooltip based on mouse position and window size
    const mouseposition = useMousePosition();
    const fmt = format(props);
    // Default style and tooltip height / width values
    const style = {
        top: 0,
        left: 0,
    };

    if (position === null) {
        style.top = mouseposition.y - 5;
        style.left = mouseposition.x + 20;
        const displayTooltipLeft = mouseposition.x > window.innerWidth - tooltipWidth;
        const displayTooltipTop = mouseposition.y > window.innerHeight - tooltipHeight;

        if (displayTooltipLeft) {
            style.left = mouseposition.x - tooltipWidth - 20;
        }

        if (displayTooltipTop) {
            style.top = mouseposition.y - tooltipHeight + 10;
        }

        if (
            window.innerHeight < tooltipHeight ||
            window.innerWidth < tooltipWidth ||
            (mouseposition.y < tooltipHeight && displayTooltipTop) ||
            (mouseposition.x < tooltipWidth && displayTooltipLeft)
        ) {
            style.top = 0;
        }
    } else {
        style.top = position.y;
        const displayTooltipLeft = position.x > window.innerWidth - tooltipWidth - position.padding;

        if (displayTooltipLeft) {
            // Display on the left
            style.left = position.x - tooltipWidth - position.padding;
        } else {
            // Display on the right
            style.left = position.x + position.padding;
        }

        if (window.innerHeight < tooltipHeight + position.y) {
            style.top = 0;
        }

        if (window.innerWidth < position.x) {
            style.left = 0;
        }
    }

    useEffect(() => {
        const tooltipNode = tooltipRef.current;

        if (tooltipNode !== null) {
            setTooltipWidth(tooltipNode.offsetWidth);
            setTooltipHeight(tooltipNode.offsetHeight);
        }
    });

    return (
        show && (
            <Portal>
                <div ref={tooltipRef} className={"datamole-ui-components-tooltip"} style={style}>
                    {Object.keys(content).length > 0 && (
                        <table>
                            <thead>
                                <tr>{title !== null && <td colSpan={colors === null ? 2 : 3}>{title}</td>}</tr>
                            </thead>
                            <tbody>
                                {Object.entries(content).map(([key, value]) => (
                                    <tr key={key} className={key === activeKey ? "active" : ""}>
                                        {colors !== null && (
                                            <td>
                                                {getColor(key, colors) !== null && (
                                                    <div
                                                        className={"square"}
                                                        style={{
                                                            backgroundColor: getColor(key, colors) ?? undefined,
                                                        }}
                                                    />
                                                )}
                                            </td>
                                        )}
                                        {displayKey && <td>{fmt(key)}</td>}
                                        <td>{fmt(value)}</td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    )}
                    {children}
                </div>
            </Portal>
        )
    );
}

function getColor(key: string, colors: Record<string, string>): string | null {
    if (typeof colors[key] === "string") {
        return colors[key];
    } else {
        return null;
    }
}
