import { memo } from "react";

import { Link } from "components/ui/Link";
import { TableCell, TableCellProps } from "components/ui/table-final-saviour/Table/TableCell";
import { Row } from "components/ui/table-final-saviour/Table/table.types";

interface Props<T extends Row> extends TableCellProps<T> {
    to: string;
}

const TableCellLink = <T extends Row>(props: Props<T>) => {
    return (
        <TableCell {...props}>
            <Link to={props.to}>{props.children}</Link>
        </TableCell>
    );
};

const TableCellLinkMemo = memo(TableCellLink) as typeof TableCellLink;

export { TableCellLinkMemo as TableCellLink };
export type { Props as TableCellLinkProps };
