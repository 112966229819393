import { MenuListItem, MenuListItemIcon } from "components/ui/MenuList";
import { useAppDispatch, useAppSelector } from "store";
import { toggleDeveloperMode } from "store/slices/user.slice";

const DEVELOPER_ROLES = [
    // Legacy role name, can be deleted after LSSA-5558 is resolved
    "Developers",
    // New role names
    "Developer",
    "Developer - Combined",
];

const DeveloperModeToggle = () => {
    const isDeveloper = useAppSelector((state) => DEVELOPER_ROLES.includes(state.user.primaryCombinedRole.name));
    const developerMode = useAppSelector((state) => state.user.developerMode);
    const dispatch = useAppDispatch();

    if (!isDeveloper) return null;

    return (
        <MenuListItem onClick={() => dispatch(toggleDeveloperMode())}>
            <MenuListItemIcon icon={"lely-icon-objects"} />
            Developer Mode - {developerMode ? "Disable" : "Enable"}
        </MenuListItem>
    );
};

export { DeveloperModeToggle };
