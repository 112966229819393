import { useComboboxMultiple, type UseComboboxMultipleArgs } from "@datamole/wds-component-combobox";
import { useState } from "react";

export const useMultiSelect = <T extends string>(
    selectOptions: { id: T; name: string }[],
    defaultSelected: T[] = [],
    config: Pick<UseComboboxMultipleArgs<T, string>, "disabled" | "hasError"> = {}
) => {
    const [selected, setSelected] = useState(defaultSelected);
    const clearSelected = () => setSelected([]);

    const multiSelect = useComboboxMultiple({
        options: selectOptions,
        value: selected,

        onChange: setSelected,
        getOptionLabel: (option) => option.name,
        getOptionValue: (option) => option?.id,

        ...config,
    });

    return {
        selected,
        setSelected,
        clearSelected,
        getClearOptionProps: (index: number) => {
            const props = multiSelect.getOptionProps(index);

            return {
                ...props,
                onClick: () => {
                    props.onClick();
                    clearSelected();
                },
            };
        },
        multiSelect,
    };
};

export type UseMultiSelectReturnType = ReturnType<typeof useMultiSelect>;
