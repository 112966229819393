import styled from "styled-components";

export const MultiSelectDivier = styled.div`
    width: 100%;

    border: unset;
    border-bottom: 1px solid var(--wds-color-border);

    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
`;
