// @ts-strict-ignore
import styled from "styled-components";

import { TableCell, TableCellProps } from "components/ui/table-final-saviour/Table/TableCell";
import { TableHeaderCell } from "components/ui/table-final-saviour/Table/TableHeaderCell";
import { Column, Row, TableCellBaseProps } from "components/ui/table-final-saviour/Table/table.types";

export type IconSize = `${string}rem` | `${string}px`;

interface IconProps<T> extends TableCellBaseProps<T> {
    size: string;
}
export interface IconColumn<T extends Row> extends Column<T>, Pick<TableCellProps<T>, "width"> {
    _type: "icon";
    Icon: (props: IconProps<T>) => React.JSX.Element | null;
    iconSize?: IconSize;
    title?: string;
}

const IconWrapperStyled = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -0.25rem;
`;

export const createIconColumn = <T extends Row>(options: Omit<IconColumn<T>, "_type">): IconColumn<T> => {
    const { Icon, iconSize } = options;

    return {
        _type: "icon",
        ...options,
        components: {
            TableHeaderCell: (props) => {
                const tableHeaderLabel = options?.title ?? null;
                return (
                    <TableHeaderCell width={options?.width} {...props}>
                        {tableHeaderLabel}
                    </TableHeaderCell>
                );
            },
            TableCell: (props) => (
                <TableCell width={options?.width} {...props} verticalAlign={"middle"}>
                    <IconWrapperStyled>
                        {/* Type error when using `props.size` - it's referring to `TableCellBaseProps<T>` instead of `IconProps<T>` within the icon. I'm just passing it as a separate prop for now. */}
                        <Icon {...props} size={iconSize ?? "1.5rem"} />
                    </IconWrapperStyled>
                </TableCell>
            ),
        },
    };
};
