import { useLocation } from "react-router-dom";
import styled from "styled-components";

import { Link } from "components/ui/Link";
import { Icon } from "components/ui-deprecated";
import { Button } from "components/ui-deprecated/Button";

const NotFoundPageStyled = styled.div`
    height: 100%;
    width: 100%;
    padding: 3rem;
`;

const Container = styled.div`
    padding: 1rem 1.5rem;

    background-color: ${({ theme }) => theme.color.white};
    border: 1px solid ${({ theme }) => theme.color.grayDairy};
    border-radius: 0.5rem;
    box-shadow: 0px 2px 5px #dedbd666;
`;

const Title = styled.div`
    margin-bottom: 1rem;

    display: flex;
    align-items: center;

    font-size: 1.25rem;
    font-weight: 500;
    color: ${({ theme }) => theme.color.textGray};
`;

const IconStyled = styled(Icon)`
    margin-right: 0.5rem;

    color: ${({ theme }) => theme.color.textGrayDairyDarker};
`;

const Content = styled.div`
    margin-bottom: 1rem;
`;

const NotFoundPage = () => {
    const { pathname } = useLocation();

    return (
        <NotFoundPageStyled>
            <Container>
                <Title>
                    <IconStyled icon={"info-circle"} />
                    Page not found
                </Title>
                <Content>
                    The URL (address, link or bookmark) you are using may no longer be valid - <code>{pathname}</code>.
                    <br />
                    You can start over on the Dashboard.
                </Content>
                <Link to={"/"}>
                    <Button>Navigate to Dashboard</Button>
                </Link>
            </Container>
        </NotFoundPageStyled>
    );
};

export { NotFoundPage };
