import type { UserAreaName } from "types/user.types";

/**
 * Logic for checking if user has access based on area or developer mode. Developer mode will skip area check.
 *
 * @param userAreas - areas user has access to
 * @param allowUserAreas - areas that are needed for access; user needs to have at least one of these areas to have access
 * @param developerMode - whether user is in developer mode
 * @param allowDeveloperMode - will skip area check if user is in developer mode and this is `true`
 * @returns whether user has access based on area or developer mode
 */
export const hasAccessBasedOnArea = ({
    userAreas,
    allowUserAreas,
    developerMode,
    allowDeveloperMode,
}: {
    userAreas: UserAreaName[];
    allowUserAreas: UserAreaName[];
    developerMode: boolean;
    allowDeveloperMode: boolean;
}) => {
    const hasArea = userAreas.some((area) => allowUserAreas.includes(area));
    const hasAccess = hasArea || (allowDeveloperMode && developerMode);

    return hasAccess;
};
